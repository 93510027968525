<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			

		</template>

		<template v-slot:header-end>

			

		</template>

		<template v-slot:main-body>
			
			<div class="w-100 h-100 d-flex align-items-center mx-auto p-4" style="max-width: 1200px;">

				<div class="w-50 h-75 flex-column align-items-center justify-content-center overflow-hidden">
					
					<div class="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
						<div class="setup-chat border border-end-0 rounded-top p-4 overflow-auto mx-auto w-100" ref="scrollContainer" style="max-width: 600px; height: calc( 100% - 90px );">
							
							<div>						
								
								<chat_message content="👋 Hey there! I'm Bella and I'm going to get you where you need to go. First off, what's your email?" type="1" author="1" />

								<chat_message v-if="active_step != 'email' && user.email" :content="user.email" :type="2" author="4" />

								<div v-if="is_registered">

									<chat_message v-if="is_invited" :content="terms_links" :type="1" author="1" />

									<chat_message v-if="is_invited && active_step != 'terms'" content="Yes, I agree" :type="2" author="4" />

									<chat_message v-if="user.email && active_step != 'terms'" content="Perfect! There should be a six digit code in your email. Enter it below and I will log you in to your account." type="1" author="1" :include_resend="true" :email="user.email" />

									<chat_message v-if="active_step == 'code' && code && error && error.message" :content="code" :type="2" author="4" />

									<chat_message v-if="active_step == 'code' && code && error && error.message" :content="error.message" :type="3" author="1" />
								</div>

								<div v-else-if="active_step != 'email'">

									<chat_message v-if="user.email" content="Perfect! Now, can you tell me the name of your awesome agency?" type="1" author="1" />

									<chat_message v-if="active_step != 'team_name' && team.name" :content="team.name" :type="2" author="4" />

									<chat_message v-if="team.name" content="Like it! Before we go any further, I don't want to be rude. Can you tell me your full name?" type="1" author="1" />

									<chat_message v-if="active_step != 'name' && user.name" :content="user.name" :type="2" author="4" />

									<chat_message v-if="user.name" :content="'Great name! It\'s lovely to meet you ' + first_name + '.'" :type="1" author="1" />

									<chat_message v-if="user.name" :content="terms_links" :type="1" author="1" />

									<chat_message v-if="auth_user && auth_user.email" content="Yes, I agree" :type="2" author="4" />

									<chat_message v-if="auth_user && auth_user.email" content="Awesome! I've just emailed you a 6 digit verification code. If you haven't received it, please double check your spam folder." type="1" author="1" :include_resend="true" :email="auth_user.email" />

									<chat_message v-if="(active_step == 'code' && code && error && error.message) || (auth_user && auth_user.email_verified_at)" :content="code" :type="2" author="4" />

									<chat_message v-if="active_step == 'code' && code && error && error.message" :content="error.message" :type="3" author="1" />

									<chat_message v-if="auth_user && auth_user.email_verified_at" content="Great! Now let's get your billing details set up. You're starting with a free trial, so no payment will be taken until your trial ends. This just helps us verify who you are and prevents misuse.<br><br>First of all, what is your billing address postcode?" :type="1" author="1" />

									<chat_message v-if="team.postcode" :content="team.postcode" :type="2" author="4" />

									<chat_message v-if="team.postcode" :content="( addresses && addresses.length ? 'Cool, I\'ve found some potential addresses, please could you select your billing address from the list?' : 'Hmm, this is embarrassing! I couldn\'t find your address. Could you please enter it manually?' )" :type="1" author="1" />

									<chat_message v-if="team.line_1" :content="team.line_1 + ', ' + team.postcode" :type="2" author="4" />

									<chat_message v-if="active_step == 'payment-method' || (auth_user && auth_user.has_payment_method)" content="Great! Now we just need a payment method. We use Stripe to keep your payment details safe and secure. 🔒" :type="1" author="1" />

									<chat_message v-if="auth_user && auth_user.has_payment_method" :content="'**** **** **** ' + team.pm_last_four" :type="2" author="4" />

									<chat_message v-if="auth_user && auth_user.has_payment_method" content="Perfect! Let's get your workspace set up so you can start creating amazing sites. A workspace is your private server for ongoing projects, and you get a free 15-day trial. 🎉<br><br>What type of workspace would you like to create?" :type="1" author="1" />

									<chat_message v-if="active_step == 'complete'" :content="subscription" :type="2" author="4" />

									<chat_message v-if="user.has_payment_method && error && error.message" :content="error.message" :type="3" author="1" />

									<chat_message v-if="active_step == 'complete'" :content="'Fantastic choice! I\'m setting up your workspace as we speak. This usually takes around 10 minutes. I\'ll email you once it\'s ready. ⏳<br><br>Your first payment will be taken automatically after your 15-day trial ends, and then it continues monthly. You can cancel anytime by deleting your workspace.<br><br>It\'s been a pleasure chatting with you, ' + first_name + '! I look forward to working with you and seeing the incredible things you create. 🚀'" :type="1" author="1" />	

								</div>
								
							</div>

						</div>

						<div class="setup-chat-actions border border-end-0 rounded-bottom border-top-0 overflow-hidden bg-white mx-auto w-100" style="max-width: 600px; height: 90px;">
							<form @submit.prevent="submit" class="d-flex align-items-center h-100">
								<div class="h-100 flex-grow-1">
									
									<form_control
										v-if="active_step == 'email'"
										type="email"
										placeholder="Enter your email address"
										name="user_email"
										:required="true"
										:error="error"
										v-model="email"
										group_class="mb-0 h-100"
									/>

									<form_control
										v-else-if="active_step == 'code'"
										type="text"
										name="code"
										placeholder="Enter your 6 digit code"
										:required="true"
										:error="error"
										v-model="code"
										group_class="mb-0 h-100"
									/>

									<form_control
										v-else-if="active_step == 'team_name'"
										type="text"
										name="team_name"
										placeholder="Enter your agency name"
										:required="true"
										:error="error"
										v-model="team_name"
										group_class="mb-0 h-100"
									/>

									<form_control
										v-else-if="active_step == 'name'"
										type="text"
										name="user_name"
										placeholder="Enter your full name"
										:required="true"
										:error="error"
										v-model="name"
										group_class="mb-0 h-100"
									/>

									<div v-else-if="active_step == 'terms'" class="p-3">
										<btn_submit 
											label="Yes, I agree" 
											icon="check"  
											:icon_after="true" 
											:loading="loading"
										/>
									</div>

									<form_control
										v-else-if="active_step == 'lookup'"
										type="text"
										name="postcode"
										placeholder="Enter your postcode"
										:required="true"
										:error="error"
										v-model="team_postcode"
										group_class="mb-0 h-100"
									/>

									<template v-else-if="active_step == 'lookup_id'">

										<form_control
											v-if="addresses && addresses.length"
											type="select"
											:options="address_options"
											name="address_id"
											placeholder="Select an address"
											:required="true"
											:error="error"
											v-model="address_id"
											group_class="mb-0 h-100"
										/>
										<div v-else class="p-3">
											<button type="button" class="d-inline-flex justify-content-between align-items-center btn btn-lg btn-outline-secondary w-100" data-bs-toggle="modal" data-bs-target="#address-modal">
												Add address

												<span class="d-flex align-items-center justify-content-center" style="width: 30px; height: 24px;">
													<span v-if="!loading" class="material-icons-outlined ms-2">add_circle_outline</span>

													<span v-else class="d-flex align-items-center justify-content-center">
														<span class="spinner-border spinner-border-sm" role="status">
															<span class="visually-hidden">Loading...</span>
														</span>
													</span>
												</span>
											</button>
										</div>

									</template>
									
									
									<div v-else-if="active_step == 'payment-method'" class="p-3">
										<button type="button" class="d-inline-flex justify-content-between align-items-center btn btn-lg btn-outline-secondary w-100" data-bs-toggle="modal" data-bs-target="#payment-modal">
											Add payment method

											<span class="d-flex align-items-center justify-content-center" style="width: 30px; height: 24px;">
												<span v-if="!loading" class="material-icons-outlined ms-2">credit_card</span>

												<span v-else class="d-flex align-items-center justify-content-center">
													<span class="spinner-border spinner-border-sm" role="status">
														<span class="visually-hidden">Loading...</span>
													</span>
												</span>
											</span>
										</button>
									</div>
									<div v-else-if="active_step == 'subscription'" class="p-3">
										<button type="button" class="d-inline-flex justify-content-between align-items-center btn btn-lg btn-outline-secondary w-100" data-bs-toggle="modal" data-bs-target="#pricing-modal">
											Select a workspace

											<span class="d-flex align-items-center justify-content-center" style="width: 30px; height: 24px;">
												<span v-if="!loading" class="material-icons-outlined ms-2">add_circle_outline</span>

												<span v-else class="d-flex align-items-center justify-content-center">
													<span class="spinner-border spinner-border-sm" role="status">
														<span class="visually-hidden">Loading...</span>
													</span>
												</span>
											</span>
										</button>
									</div>
									
									<div v-else-if="active_step == 'complete'" class="p-3">
										<router-link :to="{ name: 'workspaces' }" class="d-inline-flex justify-content-between align-items-center btn btn-lg btn-outline-secondary w-100">
											Go to dashboard
											<span class="d-flex align-items-center justify-content-center" style="width: 30px; height: 24px;">
												<span class="material-icons-outlined ms-2">east</span>
											</span>
										</router-link>
									</div>
								</div>
								<button v-if="!['terms', 'payment-method', 'subscription', 'complete'].includes( active_step )" :disabled="loading" class="bg-transparent border-0 text-primary h-100 d-flex align-items-center justify-content-center px-3 border-start" style="width: 90px;">
									<span v-if="!loading" class="material-icons-outlined">send</span>
									<div v-else class="spinner-border spinner-border-sm" role="status">
										<span class="visually-hidden">Loading...</span>
									</div>
								</button>
							</form>
						</div>
					</div>

				</div>

				<div class="w-50 h-75 align-items-center justify-content-center p-4 overflow-hidden bg-primary d-flex position-relative rounded-end">
					<div class="w-100 h-100 position-absolute top-0 start-0 opacity-50" style="background: url(https://areoi.io/wp-content/uploads/2024/04/hero-bg.svg); background-size: cover;"></div>
					<video class="position-relative img-fluid" muted autoplay loop src="https://areoi.io/wp-content/uploads/2024/04/Bella-Green-Screen.webm"></video>
				</div>

			</div>

			<payment_modal v-if="auth_user && auth_user.email_verified_at && !user.has_payment_method" v-model="team" v-on:success="submit" />

			<address_modal v-model="team" v-on:success="submit" />

			<pricing_modal v-model="size" v-on:success="submit" />

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import chat_message from '@/components/chat_message'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import payment_modal from '@/components/payment_modal'
import address_modal from '@/components/address_modal'
import pricing_modal from '@/components/pricing_modal'
import user_service from '@/services/user_service'
import team_service from '@/services/team_service'
import workspace_service from '@/services/workspace_service'
import address_service from '@/services/address_service'

export default {
	name: 'site.add',

	components: {
		layout,
		chat_message,
		form_control,
		btn_submit,
		payment_modal,
		address_modal,
		pricing_modal
	},

	data() {
		return {
			loading: false,
			logout_loading: false,
			completed_step: 0,
			editing_step: null,
			active_step: 'email',
			login_link: '<a href="/">Click here to login.</a>',
			terms_links: 'Now, for a little bit of house keeping. Please check over our <a href="https://areoi.io/?display-gdpr=terms-policy" target="_blank">terms of use</a>, <a href="https://areoi.io/?display-gdpr=acceptable-use-policy" target="_blank">acceptable use</a>, <a href="https://areoi.io/?display-gdpr=cookie-policy" target="_blank">cookie</a> and <a href="https://areoi.io/?display-gdpr=privacy-policy" target="_blank">privacy</a> policies and confirm that you agree to them?',
			is_registered: false, 
			is_invited: false,
			team: {
				name: null,
				line_1: null,
				city: null,
				state: null,
				postcode: null,
				country: 'GB'
			},
			user: {
				name: null,
				email: null,
				password: 'temppassword',
				terms: null,
				has_payment_method: false
			},
			team_name: null,
			team_postcode: null,
			name: null,
			email: null,
			password: null,
			terms: null,
			code: null,
			address_id: null,
			addresses: null,
			workspace: null,
			size: 'agency',
			size_options: [
				{
					name: "Freelancer ( 1 ongoing project - £149pm )",
					value: 'freelancer'	
				},
				{
					name: "Agency ( Upto 5 ongoing projects - £299pm )",
					value: 'agency'	
				},
				{
					name: "Enterprise ( Upto 10 ongoing projects - £499pm )",
					value: 'enterprise'	
				}
			],
			error: {}
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		first_name()
		{
			if ( this.user.name ) {
				const nameParts = this.user.name.split(' ');
				return nameParts[0]
			}

			return ''
		},

		subscription()
		{
			if ( this.size ) {
				var option = this.size_options.find( option => option.value === this.size );

				return option.name
			}

			return ''
		},

		address_options()
		{
			var options = [{
                name: 'Select an address',
                value: null
            }]
			
            if ( this.addresses ) {
                this.addresses.forEach( ( row ) => {
                    options.push({
                        name: row.address,
                        value: row.id
                    })
                })
            }
            return options
		}

	},

	mounted()
	{
		this.set_active()

		if ( this.auth_user && this.auth_user.subscribed ) {
			this.$router.push( { name: 'workspaces' } )
		}
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		navigate_scroll()
		{
			this.$nextTick(() => {
				this.scrollToBottom();
				this.focusInput();
			});
		},

		set_active()
		{
			if ( this.auth_user ) {

				user_service.auth( this.auth_user.team_id ).then(( response ) => {
					this.$store.commit( 'user/SET_USER', response.data )

					this.get_team()
					this.user = this.auth_user
					this.active_step = 'code'

					if ( this.user.email_verified_at ) {
						this.active_step = 'lookup'
						this.code = '******'
					}
					if ( this.user.has_payment_method ) {
						this.active_step = 'subscription'
					}
					if ( this.user.subscribed ) {
						this.active_step = 'complete'
					}

					this.navigate_scroll()
				}).catch( ( error ) => {
					this.error = error
				})
			}
		},

		async get_auth()
		{
			user_service.auth( this.auth_user.team_id ).then(( response ) => {
				this.$store.commit( 'user/SET_USER', response.data )

				if ( this.auth_user.subscribed ) {
					this.$router.push( { name: 'workspaces' } )
				} else {

					this.set_active()

					this.is_registered = false
				}
			}).catch( ( error ) => {
				this.error = error
			});	
		},

		async get_team()
		{
			this.reset_error()

			team_service.show( this.auth_user.team_id ).then(( response ) => {
				this.team = response.data
			}).catch( ( error ) => {
				this.error = error
			})
		},

		submit()
		{
			this.reset_error()

			if ( this.active_step == 'email' ) {
				this.check_email()
			} 

			if ( this.is_registered ) {
				if ( this.active_step == 'terms' ) {
					this.active_step = 'code'
				} else if ( this.active_step == 'code' ) {
					this.login_with_code()
				}
			} else {
				if ( this.active_step == 'team_name' ) {
					this.team.name = this.team_name
					this.active_step = 'name'
				} else if ( this.active_step == 'name' ) {
					this.user.name = this.name
					this.active_step = 'terms'
				} else if ( this.active_step == 'terms' ) {
					this.user.terms = true
					this.register()
				} else if ( this.active_step == 'code' ) {
					this.verify()
				} else if ( this.active_step == 'lookup' ) {
					this.lookup()
				} else if ( this.active_step == 'lookup_id' ) {
					if ( this.address_id ) {
						this.lookup_id()
					} else if ( this.team.line_1 && this.team.postcode ) {
						this.active_step = 'payment-method'
					}
				} else if ( this.active_step == 'payment-method' ) {
					this.get_team()
					this.active_step = 'subscription'
				} else if ( this.active_step == 'subscription' ) {
					this.create_workspace()
				}
			}

			window.$( '.modal' ).modal( 'hide' );

			this.navigate_scroll()
		},

		async check_email()
		{
			this.loading = true 

			this.user.email = this.email

			user_service.check_email({
				team: this.team,
				user: this.user
			}).then(( response ) => {
				if ( response.data ) {
					this.is_registered = true

					if ( response.data.is_invited && !response.data.is_accepted ) {
						this.active_step = 'terms'
						this.is_invited = true
					} else {
						this.active_step = 'code'
					}
				} else {
					this.active_step = 'team_name'
				}				
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
				this.navigate_scroll()
			})
		},

		async check_password()
		{
			this.loading = true 

			user_service.check_password({
				team: this.team,
				user: this.user
			}).then(() => {
				this.active_step = 5
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false

				this.navigate_scroll()
			})
		},

		async login_with_code()
		{
			this.loading = true 

			user_service.login_with_code({
				email: this.user.email,
				code: this.code
			}).then(( response ) => {
				this.$store.commit( 'user/SET_USER', response.data )
				this.get_auth()
			}).catch( ( error ) => {
				this.error = error
				this.loading = false
			})
		},

		async register()
		{
			this.loading = true 

			user_service.register({
				team: this.team,
				user: this.user
			}).then(( response ) => {
				this.$store.commit( 'user/SET_USER', response.data )
				this.active_step = 'code'
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false

				this.navigate_scroll()
			})
		},

		async verify()
		{
			this.loading = true 

			user_service.verify({
				email: this.user.email,
				verification_code: this.code,
			}).then(( response ) => {
				this.$store.commit( 'user/SET_USER', response.data )
				this.active_step = 'lookup'
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
				this.navigate_scroll()
			})
		},

		async lookup() {

			this.loading    = true

			await address_service.lookup( this.team_postcode ).then(( response ) => {
				if ( response.data && response.data.suggestions ) {
					this.addresses = response.data.suggestions
				}
				this.team.postcode = this.team_postcode
			}).finally(()=> {
				this.active_step = 'lookup_id'
				this.loading = false
				this.navigate_scroll()
			})
		}, 

		async lookup_id() {

			this.loading    = true
			
			await address_service.lookup_id( this.address_id ).then(( response ) => {
				if ( response.data ) {
					this.team.line_1 = response.data.line_1
					this.team.city = response.data.town_or_city
					this.team.state = response.data.county
					this.active_step = 'payment-method'
				}	
			}).catch((error) => {
				this.error = error
			}).finally(()=> {
				this.loading = false
				this.navigate_scroll()
			})
		},

		async create_workspace()
		{
			this.loading = true 

			workspace_service.store({
				size: this.size,
				name: this.team.name + ' 1',
				description: null,
				forget_password: true
			}).then(() => {
				this.active_step = 'complete'
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
				this.navigate_scroll()
			})
		},

		scrollToBottom() 
		{
			const container = this.$refs.scrollContainer;
			if (container) {
				container.scrollTop = container.scrollHeight;
			}
		},

		focusInput() 
		{
			window.$( '.setup-chat-actions input' ).focus();
		}
	}
}
</script>

<style>
	.setup-chat-actions .form-control,
	.setup-chat-actions .form-select {
		height: 100%;
		border: none;
		border-radius: 0;
		font-size: 1.2rem;
	}
</style>
