<template>
	
	<div class="modal fade" id="address-modal" tabindex="-1" aria-labelledby="address-modalLabel" aria-hidden="true">

		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="address-modalLabel">Add billing address</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">

					<form class="position-relative" @submit.prevent="submit">

						<form_control
							label="Address line 1"
							type="text"
							name="line_1"
							:required="true"
							:error="error"
							v-model="value.line_1"
						/>

						<div class="row">

							<div class="col">

								<form_control
									label="Town / city"
									type="text"
									name="city"
									:required="true"
									:error="error"
									v-model="value.city"
								/>

							</div>

							<div class="col">

								<form_control
									label="Postcode / zipcode"
									type="text"
									name="postcode"
									:required="true"
									:error="error"
									v-model="value.postcode"
								/>

							</div>

						</div>

						<div class="row">

							<div class="col">
								<form_control
									label="County / state"
									type="text"
									name="state"
									:required="true"
									:error="error"
									v-model="value.state"
								/>
							</div>

							<div class="col">
								<form_control
									label="Country"
									type="select"
									name="country"
									:options="store_countries"
									:required="true"
									:error="error"
									v-model="value.country"
								/>
							</div>

						</div>

						<btn_submit 
							label="Add address" 
							icon="add_circle_outline"  
							:icon_after="true" 
							:loading="loading" 
						/>

					</form>

				</div>
			</div>
		</div>
	</div>

</template>

<script>
import { mapGetters } from 'vuex';
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'

export default {
	name: 'components.address_modal',
	props: {
		modelValue: [String, Number, Object, Boolean, Array]
	},
	emits: [
		'update:modelValue',
		'success'
	],
	data() {
		return {
			error: {},
			loading: false,
		}
	},
	components: {
		form_control,
		btn_submit
	},
	computed: {

		...mapGetters( 'user', ['auth_user']),

		...mapGetters( 'countries', ['store_countries'] ),

		value: {
			get() {
				return this.modelValue
			},
			set( value ) {
				this.$emit('update:modelValue', value)			
			}
		},

	},
	mounted()
	{
		
	},
	methods: {
		reset_error()
		{
			this.error = {}
		}, 

		async submit()
		{
			this.$emit('success', true)
		}
	}
}
</script>

<style>

</style>
