<template>
	
	<div class="modal fade" id="payment-modal" tabindex="-1" aria-labelledby="payment-modalLabel" aria-hidden="true">

		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="payment-modalLabel">Add payment method</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">

					<a href="https://stripe.com/" target="_blank" class="d-block mb-4">
						<img src="@/assets/img/powered-by-stripe.png" height="35">
					</a>

					<form v-if="!this.auth_user.has_payment_method" class="position-relative" @submit.prevent="create_setup_intent">

						<loader_overlay v-if="stripe_loading" />

						<label class="form-label">Card Number</label>
						<div id="card-element-number" class="card-element position-relative mb-3 form-control p-3"></div>

						<div class="row mb-4">
							<div class="col">
								<label class="form-label">Expiry</label>
								<div id="card-element-expiry" class="card-element position-relative form-control p-3"></div>
							</div>
							<div class="col">
								<label class="form-label">CVC</label>
								<div id="card-element-cvc" class="card-element position-relative form-control p-3"></div>
							</div>
						</div>

						<btn_submit 
							label="Add payment method" 
							icon="add_circle_outline"  
							:icon_after="true" 
							:loading="stripe_loading" 
						/>

					</form>

					<div v-else>
						<p class="alert alert-success mb-0">
							Payment method added successfully.
						</p>
					</div>

				</div>
			</div>
		</div>
	</div>

</template>

<script>
import { mapGetters } from 'vuex';
import loader_overlay from '@/components/loader_overlay'
import btn_submit from '@/components/btn_submit'
import billing_service from '@/services/billing_service'
import user_service from '@/services/user_service'

export default {
	name: 'components.payment_modal',
	props: {
		modelValue: [String, Number, Object, Boolean, Array]
	},
	emits: [
		'update:modelValue',
		'success'
	],
	data() {
		return {
			error: {},
			loading: false,
			stripe_loading: false,
			team_loading: false,
			address_loading: false,
			stripe: null,
			secret: null,
		}
	},
	components: {
		loader_overlay,
		btn_submit
	},
	computed: {

		...mapGetters( 'user', ['auth_user']),

		...mapGetters( 'countries', ['store_countries'] ),

		value: {
			get() {
				return this.modelValue
			},
			set( value ) {
				this.$emit('update:modelValue', value)			
			}
		},

	},
	mounted()
	{
		if ( this.auth_user && !this.auth_user.has_payment_method ) {
			this.create_intent()
		}
	},
	methods: {
		reset_error()
		{
			this.error = {}
		}, 

		async create_intent() {

			this.stripe_loading    = true
			
			this.reset_error()

			await billing_service.create_intent().then(( response ) => {
				
				this.secret = response.data.client_secret

				this.create_element()
				
			}).catch((error) => {
				this.error = error
			})
		}, 

		async create_element()
		{			
			var vm = this

			if ( !window.$('#card-element-number').length ) {
				
				setTimeout( function() {
					vm.create_element()
				}, 500);
				
				return false
			}
			
			this.reset_error()

			this.stripe = window.Stripe( process.env.VUE_APP_STRIPE_KEY );

			this.elements = this.stripe.elements();

			this.card_element_number = this.elements.create( 'cardNumber' );
			this.card_element_number.mount('#card-element-number');

			this.card_element_expiry = this.elements.create( 'cardExpiry' );
			this.card_element_expiry.mount('#card-element-expiry');

			this.card_element_cvc = this.elements.create( 'cardCvc' );
			this.card_element_cvc.mount('#card-element-cvc');

			this.card_element_number.on('ready', function() {
				vm.stripe_loading = false
			});
		},

		async create_setup_intent()
		{
			this.stripe_loading = true
			
			let client_secret = this.secret

			const { setupIntent, error } = await this.stripe.confirmCardSetup( client_secret, {
				payment_method: {
					card: this.card_element_number,
					billing_details: {
						name: this.value.name,
						email: this.value.email,
						address: {
							city: this.value.city,
							state: this.value.state,
							postal_code: this.value.postcode,
							country: this.value.country,
						},
					}
				}
			});

			if ( error ) {
				this.error = error
				this.stripe_loading = false
			} else {

				this.add_payment_method( setupIntent )
			}
		},

		async add_payment_method( setup_intent )
		{
			await billing_service.add_payment_method( {
				intent: setup_intent,
				team: this.value
			} ).then(() => {
				
				this.reset_error()

				user_service.auth().then(( response ) => {
					
					this.$store.commit( 'user/SET_USER', response.data )

					this.$emit('success', true)

				}).catch( ( error ) => {
					this.error = error
				})
				
			}).catch((error) => {
				this.error = error
			}).finally(()=> {
				this.stripe_loading = false
			})
		}
	}
}
</script>

<style>

</style>
