import axios from 'axios'

export const client = axios.create({
	baseURL: 'https://api.getAddress.io/',
});

client.interceptors.response.use(
	( response ) => {
		return response;
	},
	function ( error ) {
		var response = ( error.response && error.response.data ? error.response.data : error );
		response.status = ( error.response && error.response.status ? error.response.status : 500 )
		return Promise.reject( response );
	}
)

export default {

	async lookup( term ) {

		return client.post( 'autocomplete/' + term + '?api-key=f18F_4EDZ0Kt_dy1oA8vBQ42909' )

	},

	async lookup_id( id ) {

		return client.get( 'get/' + id + '?api-key=f18F_4EDZ0Kt_dy1oA8vBQ42909' )

	},

}