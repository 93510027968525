<template>
	
	<div class="w-100 h-100 position-absolute top-0 start-0 d-flex align-items-center justify-content-center" style="z-index: 1;">
		<div class="w-100 h-100 bg-white"></div>
		<span class="spinner-border position-absolute" role="status">
			<span class="visually-hidden">Loading...</span>
		</span>
	</div>

</template>

<script>
export default {
	name: 'components.loader_overlay',
	props: {
		
	}
}
</script>

<style>

</style>
