<template>
	
	<div class="modal fade" id="pricing-modal" tabindex="-1" aria-labelledby="pricing-modalLabel" aria-hidden="true">

		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="pricing-modalLabel">Select a workspace</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body p-4">

					<form class="position-relative" @submit.prevent="submit">

						<div class="row row-cols-4">
							<div class="col">
								<div class="card h-100 py-4 bg-opacity-10 position-relative" :class="value == 'freelancer' ? 'border-info bg-info' : ''" @click="value = 'freelancer'" style="cursor: pointer;">
									<span v-if="value == 'freelancer'" class="badge bg-info position-absolute top-0 end-0 m-2">Selected</span>
									<div class="p-4 card-body"> 
										<h1 class="mb-2" style="font-size: 40px;"> 
											£149pm 
										</h1> 
										<p class="h3 mb-2">Freelancer</p>
										<p>1 ongoing project</p>
										<p class="small mb-0">Perfect for freelancers and hobbyists who only work on one site at a time.</p>
									</div>
								</div>
							</div>

							<div class="col">
								<div class="card h-100 py-4 bg-opacity-10 position-relative" :class="value == 'agency' ? 'border-info bg-info' : ''" @click="value = 'agency'" style="cursor: pointer;">
									<span v-if="value == 'agency'" class="badge bg-info position-absolute top-0 end-0 m-2">Selected</span>
									<div class="p-4 card-body"> 
										<h1 class="mb-2" style="font-size: 40px;"> 
											£299pm 
										</h1> 
										<p class="h3 mb-2">Agency</p>
										<p>Up to 5 ongoing projects</p>
										<p class="small mb-0">Perfect for small to medium sized agencies who work on multiple projects at once.</p>
									</div>
								</div>
							</div>

							<div class="col">
								<div class="card h-100 py-4 bg-opacity-10 position-relative" :class="value == 'enterprise' ? 'border-info bg-info' : ''" @click="value = 'enterprise'" style="cursor: pointer;">
									<span v-if="value == 'enterprise'" class="badge bg-info position-absolute top-0 end-0 m-2">Selected</span>
									<div class="p-4 card-body"> 
										<h1 class="mb-2" style="font-size: 40px;"> 
											£499pm 
										</h1> 
										<p class="h3 mb-2">Enterprise</p>
										<p>Up to 10 ongoing projects</p>
										<p class="small mb-0">Perfect for large agencies with multiple teams working on multiple projects.</p>
									</div>
								</div>
							</div>

							<div class="col">
								<div class="list-group mb-0 h-100">
									<div class="list-group-item d-flex justify-content-between align-items-center">
										<span class="fw-bold">Subtotal</span>
										<span>£{{ subtotal.toFixed(2) }}</span>
									</div>
									<div class="list-group-item d-flex justify-content-between align-items-center">
										<span class="fw-bold">VAT (20%)</span>
										<span>£{{ vat.toFixed(2) }}</span>
									</div>
									<div class="list-group-item d-flex justify-content-between align-items-center">
										<span class="fw-bold">Total</span>
										<span>£{{ total.toFixed(2) }}</span>
									</div>
									<div class="list-group-item d-flex justify-content-between align-items-center">
										<btn_submit 
											label="Start 15 day free trial" 
											icon="check"  
											:icon_after="true" 
											:loading="loading" 
										/>
									</div>
									<div class="list-group-item d-flex justify-content-between align-items-center small">
										The above amount will be taken from your payment method in 15 days and will continue on a monthly basis. Cancel anytime by deleting your workspace.
									</div>
								</div>
							</div>
							
						</div>

					</form>

				</div>
			</div>
		</div>
	</div>

</template>

<script>
import { mapGetters } from 'vuex';
import btn_submit from '@/components/btn_submit'

export default {
	name: 'components.pricing_modal',
	props: {
		modelValue: [String, Number, Object, Boolean, Array]
	},
	emits: [
		'update:modelValue',
		'success'
	],
	data() {
		return {
			error: {},
			loading: false,
		}
	},
	components: {
		btn_submit
	},
	computed: {

		...mapGetters( 'user', ['auth_user']),

		value: {
			get() {
				return this.modelValue
			},
			set( value ) {
				this.$emit('update:modelValue', value)			
			}
		},

		subtotal()
		{
			if ( this.value == 'freelancer' ) {
				return 149;
			} else if ( this.value == 'enterprise' ) {
				return 499;
			} else {
				return 299;
			}
		},

		vat()
		{
			return this.subtotal * 0.2;
		},

		total()
		{
			return this.subtotal + this.vat;
		}

	},
	mounted()
	{
		
	},
	methods: {
		reset_error()
		{
			this.error = {}
		}, 

		async submit()
		{
			this.$emit('success', true)
		}
	}
}
</script>

<style>

</style>
